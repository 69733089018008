import React from 'react';
import { Link } from 'react-router-dom';
import './List.css';
const List = ({img, alt, text}) => {
  return (
    <li className='list'>
        <Link to="#">
            <figure><img src={img} alt={alt}/></figure>
            <p>{text}</p>
        </Link>
    </li>
  );
};
export default List;
