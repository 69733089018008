import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import './Header.css';
import { Link, useNavigate } from 'react-router-dom';

const Header = ({ isLoggedIn, setIsLoggedIn }) => {
    // State to control the visibility of the nav
    const [isNavVisible, setNavVisible] = useState(false);
    const navigate = useNavigate();

    // Check login status on component mount
    useEffect(() => {
        const userToken = localStorage.getItem('userToken');
        setIsLoggedIn(!!userToken); // Update the parent state
    }, [setIsLoggedIn]); // Dependency array includes setIsLoggedIn

    // Function to toggle the nav visibility
    const toggleNav = () => {
        setNavVisible(!isNavVisible);
    };

    // Function to handle logout
    const handleLogout = () => {
        localStorage.removeItem('userToken'); // Remove the token from localStorage
        setIsLoggedIn(false); // Update the login status in the parent component
        navigate('/'); // Redirect to the homepage
    };

    return (
        <header>
            <div className='logoWrap'>
                <h2 className='logo'>
                    <Link to="/">
                        <span className='logoPrimary'>Ed</span>
                        <span className='logoSecondary'>Scroll</span>
                    </Link>
                </h2>
            </div>
            <div className='hamburgerWrap' onClick={toggleNav}>
                <FaBars />
            </div>
            <div className={`navWrap ${isNavVisible ? 'visible' : ''}`}>
                <nav className='headerMenu'>
                    <ul className="navLinks" onClick={toggleNav}>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/services">Services</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                        {!isLoggedIn && <li><Link to="/login" className='btn'>Login</Link></li>}
                        {isLoggedIn && <li><button onClick={handleLogout} className="logoutButton btn">Logout</button></li>}
                    </ul>
                </nav>  
            </div>
        </header>
    );
};

export default Header;
