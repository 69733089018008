import React from 'react';
import './Card.css';
const Card = ({img, title, description, children}) => {
  return (
    <div className='card'>
        <figure>
            <img src={img} alt="card pic"/>
        </figure>
        <h3 className='cardTitle'>{title}</h3>
        <p className='cardDescription'>{description}</p>
        {children}
    </div>
  );
};
export default Card;
