import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './Login.css'

const Login = ({ setIsLoggedIn }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(true); // State to manage loading
    const [error, setError] = useState(null); // State to manage login error
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is already logged in
        const userToken = localStorage.getItem('userToken');
        if (userToken) {
            // Redirect to home if already logged in
            navigate('/');
        } else {
            // If not logged in, stop loading and show the form
            setLoading(false);
        }
    }, [navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(null); // Reset any previous errors
        try {
            const response = await axios.post('https://edscroll.com/backend/login.php', { email, password });
            
            // Assuming the backend returns a token upon successful login
            const userToken = response.data.token;

            // Save the token to localStorage
            localStorage.setItem('userToken', userToken);

            // Update the login status in Header or the global state
            setIsLoggedIn(true);

            // Redirect the user after login
            navigate('/'); // You can also navigate to another route if needed
        } catch (error) {
            setError("Login failed. Please check your credentials."); // Set error message
            console.error("Login error:", error); // Log error for debugging
        }
    };

    // Render nothing while loading
    if (loading) return <p>Loading...</p>; // Optional loading message

    // Render the form only if loading is false
    return (
        <>
        <section className='section login'>
            <div className='container'>
                <div className='formWrap'>
                    {error && <p className="error">{error}</p>} {/* Display error message */}
                    <form onSubmit={handleLogin}>
                        <h3 className='sectionTitle'>Login</h3>
                        <div className='inputWrap'>
                            <label>Enter Email:</label>
                            <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>
                        <div className='inputWrap'>
                            <label>Enter Password:</label>
                            <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                        </div>
                        <div className='btnWrap'><button type="submit" className='btn'>Login</button></div>
                        <div className='linkWrap'>Don't have an account? <Link to="/register">Register</Link></div>
                    </form>
                </div>
            </div>
        </section>
        </>
    );
};

export default Login;
