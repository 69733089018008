import React from 'react';
import './Button.css'; // Import your button styles here

const Button = ({ href, children, className }) => {
  return (
    <div className={`btn ${className}`}>
      <a href={href}>
        {children}
      </a>
    </div>
  );
};
export default Button;
