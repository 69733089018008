import React from 'react';
import './CardIcon.css';

const CardIcon = ({children}) => {
  return (
    <div className='iconWrap'>
        {children}
    </div>
  );
};
export default CardIcon;