import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Header from './components/Header/Header';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Services from './pages/Services';
import Register from './pages/Register/Register';
import Login from './pages/Login/Login';

function BodyClass() {
  const location = useLocation();

  useEffect(() => {
    // Extract path and set it as the body class name
    const path = location.pathname.replace('/', '') || 'home'; // Default to 'home' for the root path
    document.body.id = path;
  }, [location]);

  return null; // This component doesn't render anything
}

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to manage login status

  return (
    <Router>
      <BodyClass />
      <Header setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/register" element={<Register />} />
        <Route 
          path="/login" 
          element={<Login setIsLoggedIn={setIsLoggedIn} />} // Pass setIsLoggedIn to Login
        />
      </Routes>
    </Router>
  );
};

export default App;
