import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate

const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState(''); // New state for email
    const [password, setPassword] = useState('');
    const navigate = useNavigate(); // Hook to programmatically navigate

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://edscroll.com/backend/register.php', {
                username,
                email,
                password,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            // Log the entire response to see its structure
            console.log("Response:", response);
        
            if (response.data.error) {
                alert(response.data.error); // Show specific error message from PHP
            } else if (response.data.message) {
                alert(response.data.message); // Show success message from PHP
                
                // Redirect to home page after successful registration
                navigate('/'); // Adjust the path as necessary
            } else {
                alert("Unexpected response format.");
            }
        } catch (error) {
            console.error("Error during registration:", error);
            alert("Registration failed. Please try again later.");
        }
    };
    
    return (
        <section className='section register'>
            <div className='container'>
                <div className='formWrap'>
                    <form onSubmit={handleRegister}>
                        <h3 className='sectionTitle'>Register</h3>
                        <div className='inputWrap'>
                            <label>Username:</label>
                            <input
                                type="text"
                                placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>
                        <div className='inputWrap'>
                            <label>Email:</label>
                            <input
                                type="email" // Change input type to email
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className='inputWrap'>
                            <label>Password:</label>
                            <input
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className='btnWrap'>
                            <button type="submit" className='btn'>Register</button>
                        </div>
                        <div className='linkWrap'>Allready have an account? <Link to="/login">Login</Link></div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default Register;
