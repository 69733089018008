import React from 'react';
import List from '../components/List/List';
import teacherImg from '../assets/images/icons/teacher.png';
import decisionImg from '../assets/images/icons/decision.png';
import medalImg from '../assets/images/icons/medal.png';
import scheduleImg from '../assets/images/icons/schedule.png';
import aboutBanner from '../assets/images/about-banner.jpg';


const Contact = () => {
  return (
    <>
    {/* About section */}
    <section className='section about'>
      <div className='container'>
        <h3 className='sectionSubtitle'><span className="highlight">Contact</span></h3>
        <h2 className='sectionTitle'>Over 10 Years in <span className='highlight'>Distant learning</span> for Skill Development</h2>
        <div className='sectionContent'>
          <div className='aboutBanner'>
            <figure><img src={aboutBanner}/></figure>
          </div>
          <div className='aboutContent'>
            <p className='sectionText'>Are you searching for the best home tutor in Dineshpur? Look no further! Our top-rated home tutoring services offer personalized, one-on-one instruction tailored to your child's unique learning needs. Our experienced tutor specializes in a wide range of subjects, including mathematics, science, English, and more, ensuring comprehensive support for students at all levels.</p> 
            <div className='listWrap'>
              <ul>
                <List img={teacherImg} alt='Expert Tutors' text='Expert Tutors'/>
                <List img={scheduleImg} alt='Flexible Scheduling' text='Flexible Scheduling'/>
                <List img={medalImg} alt='Boost Academic Performance' text='Boost Academic Performance'/>
                <List img={decisionImg} alt='Building Critical Skills' text='Building Critical Skills'/>
              </ul>  
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
};
export default Contact;
