import React from 'react';
import { FaPhone, FaWhatsapp, FaPen } from 'react-icons/fa';
import './Home.css';
import Button from '../components/Button/Button';
import Card from '../components/Card/Card';
import List from '../components/List/List';
import tuitionImg from '../assets/images/tuition.jpg';
import CardIcon from '../components/CardIcon/CardIcon';
import heroImg from '../assets/images/edscroll-students.png';
import teacherImg from '../assets/images/icons/teacher.png';
import decisionImg from '../assets/images/icons/decision.png';
import medalImg from '../assets/images/icons/medal.png';
import scheduleImg from '../assets/images/icons/schedule.png';
import aboutBanner from '../assets/images/about-banner.jpg';


const Home = () => {
  return (
    <>
    {/* Hero section */}
    <section className='section hero'>
      <div className='container'>
        <div className='heroContent'>
          <h1 className='sectionTitle'>We provide the best <span className="highlight">tuition</span> service in Dineshpur</h1>
          <p className='sectionText'>हम मोतीपुर, दिनेशपुर में कक्षा पहली से लेकर कक्षा दशवीं तक सभी विषय के ट्यूशन पढ़ाते हैं।</p>
          <div className='btnWrap'>
            <Button href='tel:+917534886273'>
              <FaPhone className='call' /> Call Us
            </Button>
            <Button href='https://wa.me/7534886273?text=Tuition%20in%20Dineshpur' className='secondary'>
                Whatsapp <FaWhatsapp />
            </Button>
          </div>
        </div>
        <div className='heroImage'>
          <figure><img src={heroImg} alt="Hero Banner"/></figure>
        </div>
      </div>
    </section>

    {/* courses section */}
    <section className='section courses'>
      <div className='container'>
        <h3 className='sectionSubtitle'><span className="highlight">Courses</span></h3>
        <h2 className='sectionTitle'>Sikshaa is a <span className='highlight'>community </span> for creative people</h2>
        <div className='cardWrap'>
          <Card img={tuitionImg} title='Tuition' description='Are you looking for a dedicated and qualified tutor to provide personalized home tuition in Dineshpur? We provide the best home tuition in Dineshpur and cater to major curriculums like CBSE, ICSE, and IGCSE, ensuring comprehensive support for students across different educational boards.'>
              <div className='iconWrapper'>
                <CardIcon>
                    <a href='https://wa.me/7534886273?text=Tuition%20in%20Dineshpur'>
                      <FaWhatsapp className='icon'/>
                      <p className='text'>Whatsapp</p>
                    </a>
                    
                </CardIcon>
                <CardIcon>
                    <a href='tel:+917534886273'>
                      <FaPhone className='icon phone' />
                      <p className='text'>Call</p>
                    </a>
                </CardIcon>
                <CardIcon>
                    <a href='https://wa.me/7534886273?text=Want%20to%20admission%20for%20Tuition'>
                      <FaPen className='icon'/>
                      <p className='text'>Admission</p>
                    </a>
                </CardIcon>
              </div>
          </Card>

          <Card img={tuitionImg} title='Basic Computer' description='A basic computer course provides foundational knowledge that equips individuals with the skills needed to navigate modern technology effectively. Whether you’re a student, a professional, or someone looking to enhance your digital literacy, a basic computer course is an invaluable starting point.'>
              <div className='iconWrapper'>
                <CardIcon>
                    <a href='https://wa.me/7534886273?text=Basic%20computer%20classes%20in%20Dineshpur'>
                      <FaWhatsapp className='icon'/>
                      <p className='text'>Whatsapp</p>
                    </a>
                    
                </CardIcon>
                <CardIcon>
                    <a href='tel:+917534886273'>
                      <FaPhone className='icon phone' />
                      <p className='text'>Call</p>
                    </a>
                </CardIcon>
                <CardIcon>
                    <a href='https://wa.me/7534886273?text=Want%20to%20admission%20for%20Basic%20Computer%20Classes'>
                      <FaPen className='icon'/>
                      <p className='text'>Admission</p>
                    </a>
                </CardIcon>
              </div>
          </Card>
          <Card img={tuitionImg} title='Advance Computer' description='We provide advanced computer classes tailored for individuals seeking to master complex concepts in technology. Our courses cover topics like artificial intelligence, machine learning, and advanced database management, empowering students to excel in their careers'>
              <div className='iconWrapper'>
                <CardIcon>
                    <a href='https://wa.me/7534886273?text=Advance%20computer%20classes%20in%20Dineshpur'>
                      <FaWhatsapp className='icon'/>
                      <p className='text'>Whatsapp</p>
                    </a>    
                </CardIcon>
                <CardIcon>
                    <a href='tel:+917534886273'>
                      <FaPhone className='icon phone' />
                      <p className='text'>Call</p>
                    </a>
                </CardIcon>
                <CardIcon>
                    <a href='https://wa.me/7534886273?text=Want%20to%20admission%20for%20Advance%20Computer%20Classes'>
                      <FaPen className='icon'/>
                      <p className='text'>Admission</p>
                    </a>
                </CardIcon>
              </div>
          </Card>
        </div>
      </div>
    </section>

    {/* About section */}
    <section className='section about'>
      <div className='container'>
        <h3 className='sectionSubtitle'><span className="highlight">About</span></h3>
        <h2 className='sectionTitle'>Over 10 Years in <span className='highlight'>Distant learning</span> for Skill Development</h2>
        <div className='sectionContent'>
          <div className='aboutBanner'>
            <figure><img src={aboutBanner}/></figure>
          </div>
          <div className='aboutContent'>
            <p className='sectionText'>Are you searching for the best home tutor in Dineshpur? Look no further! Our top-rated home tutoring services offer personalized, one-on-one instruction tailored to your child's unique learning needs. Our experienced tutor specializes in a wide range of subjects, including mathematics, science, English, and more, ensuring comprehensive support for students at all levels.</p> 
            <div className='listWrap'>
              <ul>
                <List img={teacherImg} alt='Expert Tutors' text='Expert Tutors'/>
                <List img={scheduleImg} alt='Flexible Scheduling' text='Flexible Scheduling'/>
                <List img={medalImg} alt='Boost Academic Performance' text='Boost Academic Performance'/>
                <List img={decisionImg} alt='Building Critical Skills' text='Building Critical Skills'/>
              </ul>  
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
};
export default Home;
